.job-card {
  padding: 20px;
  border: 1px solid #333;
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p {
  font-size: 18px;
  color: #555;
  margin: 8px 0;
}

h3 {
  font-size: 22px;
  color: #444;
  margin-top: 25px;
  margin-bottom: 10px;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  font-size: 18px;
  margin-bottom: 8px;
  color: #555;
}

.comments-section {
  margin-top: 20px;
}

.comments-section p {
  font-size: 18px;
  color: #555;
}

/* Print-specific styles */
@media print {
  .job-card {
    border: none;
    background-color: white;
  }

  h1, h3 {
    color: black;
  }

  p, li {
    color: black;
  }
}
