/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 80px; /* Set a specific height for the header */
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .nav-links {
    display: flex;
    gap: 15px;
  }
  
  .nav-link {
    padding: 5px 10px;
    text-decoration: none;
    color: #fff; /* Set text color to white */
    background-color: #c23b22; /* Update this to match your design */
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
  }
  
  .nav-link:hover {
    background-color: #a32e1c; /* Slightly darker shade for hover effect */
  }


.logout-icon {
  color: #c23b22;
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.logout-icon:hover {
  color: #a32e1c;
}

.username-display {
  color: #333;
  font-size: 1rem;
  margin-right: 15px;
  align-self: center;
}

.username {
  font-weight: bold; /* Only the username will be bold */
}

  