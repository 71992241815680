/* src/pages/LoginPage.css */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../public/assets/garage-background.jpg') no-repeat center center fixed;
    background-size: cover;
  }
  
  .login-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .error {
    color: red;
    margin-bottom: 15px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background: #555;
  }

  /* Added styles for password input with toggle icon */
.password-group .password-input-container {
  position: relative;
}

.password-group .password-input-container input {
  width: 100%;
  padding-right: 40px; /* Space for the toggle icon */
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
}

.password-toggle-icon:focus {
  outline: none;
}
  