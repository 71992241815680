/* General styling for the Accounts Page */
.accounts-page {
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Styling the search box */
  .search-box {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-box input {
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .search-box input:focus {
    outline: none;
    border-color: #c23b22;
  }
  
  /* Styling the accounts table */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
  }
  
  table th, table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  table th {
    background-color: #f4f4f4;
  }
  
  table tr:hover {
    background-color: #f9f9f9;
  }
  
  button {
    padding: 8px 16px;
    background-color: #c23b22;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #a32e1c;
  }
  
  /* Service history modal styling */
  .service-history-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: #fafafa;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 10px;
    max-height: 70vh; /* Prevent modal from growing too tall */
    overflow: auto;
    display: flex;
    flex-direction: column;
    
  }

  
  
  .service-history-modal h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Scrollable service history list */
  .service-history-list {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px; /* Space for scrollbar */
  }
  
  .service-history-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .service-history-list li:last-child {
    border-bottom: none;
  }
  
  /* Expandable description */
  .description-preview {
    display: inline-block;
    max-width: 100%;
  }
  
  .read-more {
    color: #c23b22;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .service-history-modal button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #c23b22;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
  }
  
  .service-history-modal button:hover {
    background-color: #a32e1c;
  }
  
  /* Overlay behind the modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .appointment-entry {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
  }
  
  .appointment-summary {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Add this */
    cursor: pointer;
  }

  .appointment-summary .toggle-icon {
    margin-left: 10px;
    font-size: 1.2em; /* Adjust size as needed */
  }

  .appointment-summary:hover {
    background-color: #f9f9f9;
  }
  
  .appointment-details {
    padding: 10px 0;
    font-size: 16px;
    
  }

  body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
}
  
  